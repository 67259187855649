import React, { Component } from "react"

import Layout from "../../../components/layout"
import ProductPage from "../../../components/productpage"

import image_product from '../../../images/product/k202.jpg'
import image_product_mobile from '../../../images/product/k202_mobile.jpg'

import substratelabels from "../../../content/substratelabels.json"

class K202Page extends Component {

	render() {
		return (
		  <Layout mobileTitle={true}
		  		customClass='product-title'
		  		activePath='product'
		  		pageTitle="Konstrukt&trade; Permaplast&trade; K-202 High-Performance Acrylic Render">
		  	<ProductPage
		  		pageTitle="Konstrukt&trade; Permaplast&trade; K-202 High-Performance Acrylic Render"
		  		productID={"75"}
		  		image_product={image_product}
		  		image_product_mobile={image_product_mobile}
		  		surfacelist={
		  			[
		  				{
		  					"code":0,
		  					"key":"citn",
		  					"name":substratelabels['citn']
		  				},
		  				{
		  					"code":1,
		  					"key":"cetn",
		  					"name":substratelabels['cetn']
		  				},
		  				{
		  					"code":2,
		  					"key":"cepn",
		  					"name":substratelabels['cepn']
		  				}
		  			]
		  		}

		  		/>
		  </Layout>
		);
	}
}
export default K202Page
